.main-heading {
  font-size: 4rem;
  font-weight: 500;
}

.sub-heading {
  color: #747474;
  letter-spacing: 0.8px;
  font-size: 1.5rem;
  text-transform: uppercase;
}
