.hero-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
}
@media only screen and (max-width: 1008px) {
  .hero-section {
    grid-template-columns: 1fr;
    justify-items: center;
    width: 70%;
  }
  .hero-container {
    height: initial;
    padding: 30% 0;
  }
  .hero-section-content p{
    font-size: 25px;
  }
  .hero-section img{
    /* padding: 0 10% 10% 10%; */
    padding-bottom: 10%;
  }
  .hero-section-heading{
    margin-top: 10%;
  }
}