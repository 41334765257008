@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter' ,sans-serif;
  color: white;
}

a {
  all:unset;
}

body {
  background-color: #101010;
}