.about-container{
    width: 70%;
    display: grid;
    grid-template-columns: 5fr 1fr;
    margin: 5% 0;
    min-height: 50vh;
}

.about-content h2{
    width: 60%;
    font-size: 40px;
    line-height: 52px;
    font-weight: 600;
}
.about-content h4{
    width: 60%;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    margin-top: 33px;
    margin-bottom: 29px;
}
.about-content a{
    color: #D03434;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
}
.other h3{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #D03434;
}
.other ul{
    margin-bottom: 70px;
}
.other ul li{
    margin: 20px 0px;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
}
.other ul li a:hover{
    text-decoration: underline;
    cursor: pointer;

}

@media screen and (max-width: 1008px) {
    .about-content h1{
        font-size: 70px;
        width: 70%;
    }
    .about-content h2{
        font-size: 25px;
        line-height: 35px;
        word-spacing: 8px;
        width: 90%;
    }
}
@media screen and (max-width: 800px) {
    .about-content h4{
        font-size: 16px;
        width: 100%;
        line-height: 25px;
    }
    .about-container{
        grid-template-columns: 1fr;
    }
    .other ul li{
        font-size: 18px;
        line-height: 22px;

    }
}
