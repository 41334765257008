.navbar-container {
  position: fixed;
  width: 100%;
  top: -80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.navbar {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  height: 50px;
  background-color: #101010;
}

.navbar-link {
  all: unset;
  padding: 0 20px;
  font-size: 16px;
  cursor: pointer;
}
.activenavlink {
  all: unset;
  padding: 0 20px;
  font-size: 16px;
  cursor: pointer;
  /* color: rgb(208, 52, 52); */
}

.hamburger-button{
  display: none;
}


@media only screen and (max-width: 641px) {
  /* .navbar-links-container {
    display: none;
  } */
  .hamburger-button{
    display: block;
    position: absolute;
    top: 0;
    right: 5%;
    height: 80px;
    width: 80px;
    padding: 20 px;
  }
  .hamburger-button span{
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: transform 0.5s, width 0.5s;
  }
  .hamburger-button .line-1{
    transform: translate(-50%,-15px);
  }
  .hamburger-button .line-3{
    transform: translate(-50%,11px);
  }

  .activehamburger .line-1{
    transform: translate(-50%,-50%) rotate(-45deg);
  }
  .activehamburger .line-3{
    transform: translate(-50%,-50%) rotate(45deg);
  }
  .activehamburger .line-2{
    width: 0;
  }
  .navbar-links-container{
    height: calc(100vh - 80px);
    width: 100%;
    background-color: #101010;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: 25%;
    top: 80px;
    left: 100%;
    transition: 0.5s;
  }
  .navbar-link{
    font-size: 30px;
    padding: 20px 30px;
  }
  .activemobnav{
    left: 0;
  }
  .navbar-link a{
    display: block;
    width: 100%;
    height: 100%;
  }

}

