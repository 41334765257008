.container {
  display: grid;
  min-height: 90vh;
  height: fit-content;
  width: 70%;
  margin: 10vh auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.25fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Heading Content"
    "Bottom Bottom";
}

.Bottom {
  grid-area: Bottom;
  margin: auto;
  height: fit-content;
}
/* 
.Bottom img {
  height: 400px;
} */

.Heading {
  grid-area: Heading;
  margin: 0 0 10vh;
}

.Content {
  grid-area: Content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 80%;
  font-size: 18px;
  margin: 0 0 10vh;
}

@media only screen and (max-width: 800px) {
  .container {
    display: flex;
    flex-wrap: wrap;
  }
  .Heading {
    grid-area: Heading;
    margin: 0;
    height: fit-content;
  }
  .Content{
    margin: 0;
  }
}
