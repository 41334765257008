.make-transparent {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.instagram {
  background: linear-gradient(
    92.32deg,
    #505bd1 -2.26%,
    #fec45d 27.48%,
    #e93e59 49.2%,
    #da2e78 82.85%
  );
  background-clip: text;
}



