.filit-container{
    width: 70%;
    margin-top: 200px;
}
.filit-container h4{
    font-family: Inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    margin: 50px 0px;
}
@media only screen and (max-width:1008px) {
    .filit-container{
        margin-top: 100px;
    }
    .filit-container h4{
        font-size: 16px;
        margin: 30px 0;
        line-height: 25px;
    }
}
