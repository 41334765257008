.intro-container{
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    transition: 0.5s;
}
.goup{
    margin-top: calc(-100vh);
    /* display: none; */
}
@media screen and (max-width:800px){
    .videoTag{
        width: 150vh;
    }
}